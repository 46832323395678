'use client';
import {bp, SIDEBAR_WIDTH, COLORS, HEADER_HEIGHT} from 'core/styles';
import styled from 'styled-components';

export let ContentContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${bp('md')} {
        left: ${SIDEBAR_WIDTH};
    }
`;

export let Inner = styled.div`
    background-color: #fff;
    position: absolute;
    top: ${HEADER_HEIGHT};
    right: 0;
    bottom: 0;
    left: 0;
    margin-top: 24px;

    ${bp('md')} {
        top: 30px;
    }
`;
